var render = function render(){
  var _vm$applicantDetail, _vm$applicantDetail2, _vm$applicantDetail3, _vm$applicantDetail4, _vm$applicantDetail5, _vm$applicantDetail6, _vm$applicantDetail7, _vm$applicantDetail8, _vm$applicantDetail9, _vm$applicantDetail10, _vm$applicantDetail11, _vm$applicantDetail12, _vm$applicantDetail13, _vm$applicantDetail14, _vm$applicantDetail15, _vm$applicantDetail16, _vm$applicantDetail17, _vm$applicantDetail18, _vm$applicantDetail19;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "applicant-detail"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-10"
  }, [_vm._v(_vm._s((_vm$applicantDetail = _vm.applicantDetail) === null || _vm$applicantDetail === void 0 ? void 0 : _vm$applicantDetail.name))]), _c('div', {
    staticClass: "bg-white mb-10"
  }, [_c('div', {
    staticClass: "flex items-stretch shadow-border-b mdmax:flex-col"
  }, [_c('div', {
    staticClass: "w-3/5 shadow-border-r p-4 mdmax:shadow-none mdmax:w-full"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8 mdmax:text-base"
  }, [_vm._v("Data Diri")]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nama")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail2 = _vm.applicantDetail) === null || _vm$applicantDetail2 === void 0 ? void 0 : _vm$applicantDetail2.name))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Alamat Email")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail3 = _vm.applicantDetail) === null || _vm$applicantDetail3 === void 0 ? void 0 : _vm$applicantDetail3.email))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nomor KTP")]), _c('p', {
    staticClass: "text-lg mdmax:text-base mdmax:break-all"
  }, [_vm._v(_vm._s((_vm$applicantDetail4 = _vm.applicantDetail) === null || _vm$applicantDetail4 === void 0 ? void 0 : _vm$applicantDetail4.id_card_no))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nomor Handphone")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail5 = _vm.applicantDetail) === null || _vm$applicantDetail5 === void 0 ? void 0 : _vm$applicantDetail5.phone_no))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Tempat Lahir")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail6 = _vm.applicantDetail) === null || _vm$applicantDetail6 === void 0 ? void 0 : _vm$applicantDetail6.pob))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Alamat rumah")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail7 = _vm.applicantDetail) === null || _vm$applicantDetail7 === void 0 ? void 0 : _vm$applicantDetail7.address))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Tanggal Lahir")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(_vm._f("formatDate")((_vm$applicantDetail8 = _vm.applicantDetail) === null || _vm$applicantDetail8 === void 0 ? void 0 : _vm$applicantDetail8.dob)))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("RT/RW")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail9 = _vm.applicantDetail) === null || _vm$applicantDetail9 === void 0 ? void 0 : _vm$applicantDetail9.rtrw))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Jenis Kelamin")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(((_vm$applicantDetail10 = _vm.applicantDetail) === null || _vm$applicantDetail10 === void 0 ? void 0 : _vm$applicantDetail10.gender) === 'M' ? 'Laki-laki' : 'Perempuan'))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kel / Desa")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail11 = _vm.applicantDetail) === null || _vm$applicantDetail11 === void 0 ? void 0 : _vm$applicantDetail11.village))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Golongan Darah")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail12 = _vm.applicantDetail) === null || _vm$applicantDetail12 === void 0 ? void 0 : _vm$applicantDetail12.blood_type))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kecamatan")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail13 = _vm.applicantDetail) === null || _vm$applicantDetail13 === void 0 ? void 0 : _vm$applicantDetail13.subdistrict))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Pekerjaan")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail14 = _vm.applicantDetail) === null || _vm$applicantDetail14 === void 0 ? void 0 : _vm$applicantDetail14.job_type))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kode Pos")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantDetail15 = _vm.applicantDetail) === null || _vm$applicantDetail15 === void 0 ? void 0 : _vm$applicantDetail15.postal_code))])])])]), _c('div', {
    staticClass: "w-2/5 p-4 mdmax:w-full"
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Foto Selfie")]), _c('a', {
    staticClass: "inline-block",
    attrs: {
      "href": (_vm$applicantDetail16 = _vm.applicantDetail) === null || _vm$applicantDetail16 === void 0 ? void 0 : _vm$applicantDetail16.selfie_pict,
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "max-h-48 mdmax:max-w-full",
    attrs: {
      "src": (_vm$applicantDetail17 = _vm.applicantDetail) === null || _vm$applicantDetail17 === void 0 ? void 0 : _vm$applicantDetail17.selfie_pict
    }
  })])]), _c('div', {
    staticClass: "mb-8"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Foto KTP")]), _c('a', {
    staticClass: "inline-block",
    attrs: {
      "href": (_vm$applicantDetail18 = _vm.applicantDetail) === null || _vm$applicantDetail18 === void 0 ? void 0 : _vm$applicantDetail18.selfie_id_pict,
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "max-h-48 mdmax:max-w-full",
    attrs: {
      "src": (_vm$applicantDetail19 = _vm.applicantDetail) === null || _vm$applicantDetail19 === void 0 ? void 0 : _vm$applicantDetail19.selfie_id_pict
    }
  })])])])])]), _c('TableComponent', {
    attrs: {
      "columns": _vm.columns,
      "list": _vm.applicantResult,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (_ref) {
        var _entry$schedule_info, _entry$schedule_info2, _entry$schedule_info3;
        var entry = _ref.entry,
          column = _ref.column;
        return [column.field === 'action' ? _c('LinkButton', {
          attrs: {
            "buttonText": "Lihat rincian",
            "url": `/applicant/detail/${_vm.applicantId}/result/${entry.id}`,
            "linkType": "internal",
            "type": "secondary",
            "size": "small"
          }
        }) : column.field === 'date' ? _c('div', {
          staticClass: "whitespace-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(entry === null || entry === void 0 ? void 0 : (_entry$schedule_info = entry.schedule_info) === null || _entry$schedule_info === void 0 ? void 0 : _entry$schedule_info.schedule_time)) + " ")]) : column.field === 'time' ? _c('div', [_vm._v(" " + _vm._s(_vm._f("formatTime")(entry === null || entry === void 0 ? void 0 : (_entry$schedule_info2 = entry.schedule_info) === null || _entry$schedule_info2 === void 0 ? void 0 : _entry$schedule_info2.schedule_time)) + " ")]) : column.field === 'status' ? _c('div', [_c('span', {
          staticClass: "inline-block text-sm font-medium px-2 py-1 rounded-md",
          class: [entry.score_status === 'passed' ? 'bg-green-light text-green' : 'bg-red-light text-system-error']
        }, [_vm._v(" " + _vm._s(entry.score_status_display) + " ")])]) : column.field === 'location' ? _c('div', [_vm._v(" " + _vm._s(entry === null || entry === void 0 ? void 0 : (_entry$schedule_info3 = entry.schedule_info) === null || _entry$schedule_info3 === void 0 ? void 0 : _entry$schedule_info3.location_test_display) + " ")]) : column.field === 'qs_number' ? _c('div', [_vm._v(" " + _vm._s(entry === null || entry === void 0 ? void 0 : entry.qs_number) + " ")]) : _c('span', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }])
  }), _vm.applicantResult && _vm.applicantResult.length > 0 ? _c('div', {
    staticClass: "mt-8 flex justify-end items-center mb-6"
  }, [_c('pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }